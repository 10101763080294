import React from 'react'
import { useNavigate } from "react-router-dom";
//import { dialogContext } from '../contexts/DialogContext';

import { Link } from '@mui/material';

function About() {
  //const { setDialog } = React.useContext(dialogContext);
  let navigate = useNavigate();
  /*
  const ble = () =>{
    let path = `/ble`;
    navigate(path);
  }*/

  /*const startDialog=()=> {
    setDialog({open:true, title:"It's not an easterEgg..... or ...... ??", message:['Nothing to see here!'], okButton:"OK", cancelButton:"Cancel", callback:callback, cancelCallback:cancelCallback})
  }*/

  return (
    <>
        <h2>Infos</h2>
        <p style={{fontFamily:'Courier New'}}>
          Alpha release 16/11/2024<br/>
        </p>
        <Link style={{ cursor: 'pointer' }} onClick={()=>{navigate("/privacy")}} variant="body">Confidentialité/Privacy</Link>
    </>
  );
}

export default About
