import React from 'react'
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material'
import SportsBarIcon from '@mui/icons-material/SportsBar';
import { useTranslation } from "react-i18next";
import Divider from '@mui/material/Divider';
import HelpIcon from '@mui/icons-material/Help';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const config = require('../config/default');

function Home() {

  const beer=() => {
    return(
      <div id="beer">
        Made of <SportsBarIcon /> & pretzels !
      </div>
    );
  }

  const { t } = useTranslation();
  const [advance, setAdvance] = React.useState(false);
  const [showapp, setShowapp] = React.useState(false);

  const displayW = () => {
    if (advance===false) {
      return(  
      <>    
        <Box sx={{ gridColumn: "span 20", display:"flex", alignItems:"center"}} style={{ cursor: 'pointer' }} onClick={()=>{setAdvance(true)}}>A quoi sert cette application ? (cliquer pour détailler)<ArrowDropDownIcon/></Box>
      </>
      )
    } else {
      return(
      <>    
        <Box sx={{ gridColumn: "span 20", display:"flex", alignItems:"center"}} style={{ cursor: 'pointer' }} onClick={()=>{setAdvance(false)}}>Réduire<ArrowDropUpIcon/></Box>
          <br/>Cette application permet de créer des RDV (meets) "à thème" sur votre lieu de travail ou lors de déplacements professionnels.
          <br/>Vous pouvez choisir d'être notifié(e) lorsque de nouveaux meets sont créés sur vos lieux d'intérêts afin de vous y inscrire.
          <br/>Vos feedbacks sont importants, merci de les partager <a href="https://meeteurs.atlassian.net/servicedesk/customer/portal/1/group/1/create/10010" style={{"color":"inherit"}}>ici<Typography color="red" fontWeight='bold'></Typography></a>
      </>)
    }
  }
    
  const displayApp = () => {
    if (showapp===false) {
      return(  
      <>    
        <Box sx={{ gridColumn: "span 20", display:"flex", alignItems:"center"}} style={{ cursor: 'pointer' }} onClick={()=>{setShowapp(true)}}>Comment obtenir les apps Android ou iOS sur l'Alpha release ? (cliquer pour détailler)<ArrowDropDownIcon/></Box>
      </>
      )
    } else {
      return(
      <> 
        <Box sx={{ gridColumn: "span 20", display:"flex", alignItems:"center"}} style={{ cursor: 'pointer' }} onClick={()=>{setShowapp(false)}}>Réduire cette section <ArrowDropUpIcon/></Box>   
        <br/>Les App mobiles sont disponibles sur le store Android et sur Testflight sur Apple.<Typography color="red" fontWeight='bold'>Il est nécessaire pour accéder à l'alpha release d'en faire la demande via <a href="https://meeteurs.atlassian.net/servicedesk/customer/portal/1/group/1/create/10009"><font color="red">ce lien</font></a> ou par mail à <a href="mailto:meeteurs.com@gmail.com?subject=Demande d'accès à Meeteurs Alpha"><font color="red">meeteurs.com@gmail.com</font></a> en précisant:</Typography>
        <Typography color="red" fontWeight='bold'>- Votre identifiant (email) Gmail (pour l'application Android ET iOS)</Typography>
        <Typography color="red" fontWeight='bold'>- Votre compte Apple (uniquement pour l'application iOS)</Typography>
        <br/><br/>
        <Divider/>
        <u>{t('home.restrictions.title')}</u><br/>
          <List dense={true}>
            <ListItem sx={{display:'grid', listStyleType: 'disc'}}>
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="Pas d'envoie de mail"
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="Utilisez obligatoirement la connexion via Google, la validation par mail n'étant pas possible."
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary="La base de données peut être vidée sans préavis."
              />
              <ListItemText
                sx={{ display: 'list-item' }}
                primary={beer()}
              />
            </ListItem>
          </List>
      </>)
    }
  }


  return (
    <Box
      sx={{
        margin:2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        minHeight: 'calc(80vh)'
      }}
    >
      
      <Typography component="h2" variant="h4" marginBottom="40px">
          <Box sx={{               
              mr: 2,
              fontFamily: 'monospace',
              fontWeight: 300,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none' }}>
            MEETEURS
            <sup style={{fontSize:"8.0pt"}}>Alpha</sup>
            
          </Box>
      </Typography>
      <Typography variant="body" fontWeight="bold">
        Bienvenue sur l'application Meeteurs<br/><br/>
        
        {displayW()}
        <br/><u>{t('home.logininfo1')}<HelpIcon/>{t('home.logininfo2')}</u><br/><br/>
        {displayApp()}
      </Typography>
      

    </Box>
  );
}

export default Home
