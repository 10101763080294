// Tour messages selector

function setTour(location, setSteps, t, breakpoint) {
    // Default menu targets
    let menumeeteurs=".menumeeteurs"
    let menumap=".menumap"
    let menumeets=".menumeets"
    
    if (breakpoint==="xs" || breakpoint==="sm") {
        // Compact menu
        menumeeteurs=".menumeeteurs_c"
        menumap=".menumap_c"
        menumeets=".menumeets_c"
    }

    if (location.pathname==="/" || location.pathname.includes("/map")) { 
      
        setSteps(
          [
            {  
              content: <h2>{t('tour.login')}</h2>,
              target: '.menulogin',
              placement: "top",
              offset: 0,
              disableBeacon: true
            },
            {  
              content: <h2>{t('tour.menu')}</h2>,
              target: '.menumeetscompact',
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.meeteurs')}</h2>,
              target: menumeeteurs,
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.map')}</h2>,
              target: menumap,
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.meet')}</h2>,
              target: menumeets,
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.support')}</h2>,
              target: '.menusupport',
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.theme')}</h2>,
              target: '.menutheme',
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.alertes')}</h2>,
              target: '.menualertes',
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.messages')}</h2>,
              target: '.menumessages',
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.maprecherche')}</h2>,
              target: '.maprecherche',
              placement: "top",
              offset: 0
            },
            {  
              content: <h2>{t('tour.flytome')}</h2>,
              target: '.flytome',
              placement: "right-end",
              offset: 0
            },
            {  
              content: <h2>{t('tour.sharelocation')}</h2>,
              target: '.sharelocation',
              placement: "right-end",
              offset: 0
            },
            {  
              content: <h2>{t('tour.flytomeet')}</h2>,
              target: '.flytomeet',
              placement: "right-end",
              offset: 0
            },
            {  
              content: <h2>{t('tour.gotochat')}</h2>,
              target: '.gotochat',
              placement: "right-end",
              offset: 0
            }
          ])
      } else if (location.pathname==="/user") {
          setSteps(
            [{  
                content: <h2>{t('tour.profilimage')}</h2>,
                target: '.profilimage',
                placement: "auto",
                offset: 0,
                disableBeacon: true
              },
              {  
                content: <h2>{t('tour.jobs')}</h2>,
                target: '.jobs',
                placement: "auto",
                offset: 0
              },
              {  
                content: <h2>{t('tour.defaultlocation')}</h2>,
                target: '.defaultlocation',
                placement: "auto",
                offset: 0
              },
              {  
                content: <h2>{t('tour.autolocation')}</h2>,
                target: '.autolocation',
                placement: "auto",
                offset: 0
              },
              {  
                content: <h2>{t('tour.tags')}</h2>,
                target: '.tags',
                placement: "auto",
                offset: 0
              }
            ])
      } else if (location.pathname==="/settings") {
        setSteps(
          [{  
              content: <h2>{t('tour.notifdefaultloc')}</h2>,
              target: '.notifdefaultloc',
              placement: "auto",
              offset: 0,
              disableBeacon: true
            },
            {  
              content: <h2>{t('tour.newsletter')}</h2>,
              target: '.newsletter',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.notifaround')}</h2>,
              target: '.notifaround',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.notiflist')}</h2>,
              target: '.notiflist',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.realmlist')}</h2>,
              target: '.realmlist',
              placement: "auto",
              offset: 0
            }
          ])
      } else if (location.pathname.includes("/meetinfos")) {
        setSteps(
          [{  
              content: <h2>{t('tour.joinmeet')}</h2>,
              target: '.joinclass0',
              placement: "auto",
              offset: 0
            },
            { 
              content: <h2>{t('tour.sharemeet')}</h2>,
              target: '.shareclass0',
              placement: "auto",
              offset: 0
            }
          ])
        } else if (location.pathname.includes("/mymeets")) {
        setSteps(
          [{  
              content: <h2>{t('tour.editmeet')}</h2>,
              target: '.editclass0',
              placement: "auto",
              offset: 0,
              disableBeacon: true
            },
            {  
              content: <h2>{t('tour.chatmeet')}</h2>,
              target: '.chatclass0',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.mapmeet')}</h2>,
              target: '.mapclass0',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.quitmeet')}</h2>,
              target: '.quitclass0',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.sharemeet')}</h2>,
              target: '.shareclass0',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.joinmeet')}</h2>,
              target: '.joinclass0',
              placement: "auto",
              offset: 0
            }
          ])
      } else if (location.pathname==="/meetexplore") {
        setSteps(
          [{  
              content: <h2>{t('tour.meetsearchlocation')}</h2>,
              target: '.defaultlocation',
              placement: "auto",
              offset: 0,
              disableBeacon: true
            },
            {  
              content: <h2>{t('tour.autolocation')}</h2>,
              target: '.autolocation',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.meetsearchdistance')}</h2>,
              target: '.maxdistance',
              placement: "auto",
              offset: 0
            }
          ])
      } else if (location.pathname.includes("/meet")) {
        setSteps(
          [{  
              content: <h2>{t('tour.objectmeet')}</h2>,
              target: '.objectmeet',
              placement: "auto",
              offset: 0,
              disableBeacon: true
            },
            {  
              content: <h2>{t('tour.datetimemeet')}</h2>,
              target: '.datetimemeet',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.meetlocation')}</h2>,
              target: '.defaultlocation',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.autolocation')}</h2>,
              target: '.autolocation',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.tags')}</h2>,
              target: '.tags',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.maxmeeteurs')}</h2>,
              target: '.maxmeeteurs',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.meetgroup')}</h2>,
              target: '.meetgroup',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.copytag')}</h2>,
              target: '.copytag',
              placement: "auto",
              offset: 0
            },
            {  
              content: <h2>{t('tour.copylocation')}</h2>,
              target: '.copylocation',
              placement: "auto",
              offset: 0
            }
          ])
      } else if (location.pathname==="/about") {
        setSteps(
          [{  
              content: <h2>{t('tour.about')}</h2>,
              target: 'body',
              placement: "center"
            }
          ])
      } else if (location.pathname.includes("/chat")) {
        setSteps(
          [{  
            content: <h2>{t('tour.chatstate')}</h2>,
            target: '.chatstate',
            placement: "auto",
            offset: 0,
            disableBeacon: true
          },
          {  
            content: <h2>{t('tour.chatmeeteurs')}</h2>,
            target: '.chatmeeteurs',
            placement: "auto",
            offset: 0
          },
          {  
            content: <h2>{t('tour.sharelocation')}</h2>,
            target: '.sharelocation',
            placement: "right-end",
            offset: 0
          },
          {  
            content: <h2>{t('tour.gotomap')}</h2>,
            target: '.gotomap',
            placement: "auto",
            offset: 0
          }
          ])
      } else {
        setSteps(
          [{  
              content: <h2>{t('tour.unknownpage')}</h2>,
              target: 'body',
              placement: "center"
            }
          ])
      }


}

export default setTour